import _fs from "fs";
import _path from "path";
import _confdir from "confdir";
import _append from "append";
import _process from "process";
var exports = {};

function _nullRequire(id) {
  var e = new Error("Cannot find module '" + id + "'");
  e.code = "MODULE_NOT_FOUND";
  throw e;
}

var process = _process;
// tpl - a general purpose template cli
// (c) 2011 Paul Vorbach. Licensed under MIT.
;
(function () {
  var fs = _fs;
  var path = _path;
  var confdir = _confdir;
  var append = _append; // default configuration

  var defaultConf = {
    parsers: {
      default: "props-markdown"
    },
    templates: {
      default: {
        file: "default.ejs",
        engine: "ejs",
        ext: ".txt"
      }
    },
    properties: {}
  };

  function apply(file, opt, cb) {
    // look for configuration directory
    confdir(process.cwd(), "conf", function (err, dir) {
      try {
        if (err) dir = path.resolve(new URL(import.meta.url.slice(0, import.meta.url.lastIndexOf("/"))).pathname, ".tpl"); // read configuration file

        var json = fs.readFileSync(path.resolve(dir, "conf.json"), "utf8");
        var conf = JSON.parse(json); // set configuration

        conf = append(defaultConf, conf);
        var ext = path.extname(file);
        var parser; // determine parser

        if (typeof conf.parsers[ext] != "undefined") parser = conf.parser[ext];else parser = conf.parsers.default; // require parser

        parser = _nullRequire(path.resolve(dir, "parsers", parser + ".js")); // overwrite global properties

        opt = append(conf.properties, opt); // parse file

        var doc = append(opt, parser(file));
        var tpl; // determine template and engine

        if (typeof doc.template != "undefined") tpl = conf.templates[doc.template];else tpl = conf.templates.default; // resolve template file

        tpl.file = path.resolve(dir, "templates", tpl.file); // require template engine

        tpl.engine = _nullRequire(path.resolve(dir, "templates", tpl.engine + ".js")); // render

        cb(null, tpl.engine(tpl.file, doc));
      } catch (err) {
        cb(err);
      }
    });
  }

  exports.apply = apply;
}).call(exports);
export default exports;
export const apply = exports.apply;